import {
  getAllAppointments,
  getAppoinmentsByHospitalId,
} from "../../../api/appointmentAxios";
import { useState, useEffect } from "react";
import {
  BarsArrowUpIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";

export default function LeaderAppointmentTableContent({ title }) {
  const [appointments, setAppointments] = useState([]);
  // const location = useLocation(); // Use location to get query params

  // const [totalAppointments, setTotalAppointments] = useState(0); // Total number of doctors

  //   const { hospitalId } = useParams();

  //   useEffect(() => {
  //     const getData = async () => {
  //       try {
  //         if (hospitalId) {
  //           const response = await getAppoinmentsByHospitalId(hospitalId);
  //           setAppointments(response.data.body);
  //         } else {
  //           const response = await getAllAppointments();
  //           setAppointments(response.data.body);
  //           console.log(response.data.body);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching hospitals:", error);
  //       }
  //     };
  //     getData();
  //   }, [hospitalId]);

  return (
    <div className="divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
      <div className="border-b border-gray-200 p-4 px-8 pb-5 sm:flex sm:items-end sm:justify-between">
        <h3 className="text-xl font-semibold text-gray-900">Appointments</h3>

        <div className="mt-3 sm:ml-4 sm:mt-0">
          <label htmlFor="mobile-search-candidate" className="sr-only">
            Search
          </label>
          <div className="flex rounded-md">
            {/* <button
                className="mr-4 bg-red-500 p-1 px-4 hover:bg-red-700 rounded-md text-white"
                // onClick={() => setOpen(true)} // Show the form when button is clicked
              >
                Add Appointment
              </button> */}
            <div className="relative grow focus-within:z-10">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  aria-hidden="true"
                  className="size-5 text-gray-400"
                />
              </div>
              <input
                id="mobile-search-candidate"
                name="mobile-search-candidate"
                type="text"
                placeholder="Search"
                className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:hidden"
              />
              <input
                id="desktop-search-candidate"
                name="desktop-search-candidate"
                type="text"
                placeholder="Search candidates"
                className="hidden w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-sm/6 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:block"
              />
            </div>
            <button
              type="button"
              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <BarsArrowUpIcon
                aria-hidden="true"
                className="-ml-0.5 size-5 text-gray-400"
              />
              Sort
              <ChevronDownIcon
                aria-hidden="true"
                className="-mr-1 size-5 text-gray-400"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="mt-8 flow-root  ">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {appointments.length === 0 ? (
              <div className="min-w-full divide-y divide-gray-300">
                Loading...
              </div>
            ) : (
              <table className="min-w-full divide-y mx-4 divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Doctor
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Hospital
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Time
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {appointments.map((appointment) => (
                    <tr key={appointment.appointmentId}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {appointment.doctor.firstName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {appointment.hospital.hospitalName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {appointment.appointmentDate}
                      </td>
                      <td className="whitespace-nowrap text-sm text-gray-500">
                        {appointment.appointmentTime}
                      </td>
                      {/* <td className="whitespace-nowrap text-sm text-gray-500">
                          <button className="border content-center px-3 py-2 hover:bg-gray-800 hover:text-white">
                            View Appointment
                          </button>
                        </td> */}
                      <td className="whitespace-nowrap text-sm">
                        <button className="border content-center px-3 py-2 bg-red-400 text-white hover:bg-gray-800 hover:text-white">
                          Cancel Appointment
                        </button>
                      </td>
                      {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <a
                          href="#"
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit<span className="sr-only">, {person.name}</span>
                        </a>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
