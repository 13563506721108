import { getRequest, postRequest } from "./axios.js";

export const getAllDoctors = async (rowsPerPage, currentPage) => {
  const url = `/api/doctor/getAllDoctors`;
  const response = await getRequest(url);
  return response;
};
export const addDoctor = async (data) => {
  const url = "/api/doctor";
  console.log(`came in add doctor ${JSON.stringify(data)}`);
  const response = await postRequest(url, data);
  return response;
};
