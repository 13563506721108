import React, { useState } from "react";
import Container from "../components/app-components/Appointment/Container";
import Tabs from "../components/app-components/Appointment/Tabs";

const Booking = () => {
  const [activeTab, setActiveTab] = useState("Single Booking");
  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6">
        <div className="border-b border-gray-200 pb-5">
          <h3 className="text-base font-semibold text-gray-900">Bookings</h3>
        </div>
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
        <Container activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
    </div>
  );
};

export default Booking;
