import React, { useState } from "react";
import "../../src/login.css";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === "leader") {
      navigate("/leader/hmate/dashboard");
    } else {
      navigate("/hospital/hmate/dashboard");
    }
  };

  return (
    <div className="login-page">
      <div className="absolute top-[20%] left-[15%] flex items-center space-x-2">
        <img
          alt="Mivada"
          src="https://cdn.prod.website-files.com/641a367d3597374ded5823b1/641ae4d07388d7f7dc82160c_Mivada_Icon_Melon_RGB.svg"
          className="h-8 w-auto"
        />
        <p className="text-white font-sans tracking-wide font-semibold  text-2xl">
          Mivada
        </p>
      </div>
      <div className="absolute top-[40%] left-[15%] flex items-center space-x-2">
        <p className="font-sans font-semibold text-4xl">
          Login into <br />
          your account
        </p>
      </div>
      <div className="absolute top-[50%] left-[15%] flex items-center space-x-2">
        <p className="font-sans text-md text-gray-400 mt-3">
          Get the access to the numerous features!
        </p>
      </div>
      <div className=" absolute top-[25%] right-[20%] overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:p-6">
          <div className="login-form mx-auto p-6 bg-white rounded-lg w-96">
            {/* <h1 className="text-2xl font-bold text-center mb-6 text-black">
              Login
            </h1> */}
            <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email"
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Password:
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Enter your password"
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                />
              </div>
              <button
                type="submit"
                className="w-full py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition my-6"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
