import React from "react";
import { HomeIcon, UsersIcon } from "@heroicons/react/24/outline";
import { ReactComponent as DoctorIcon } from "../assets/icons/Doctor.svg";
import StatsContent from "../components/app-components/Dashboard/StatsContent";
import HospitalTableContent from "../components/app-components/Dashboard/HospitalTableContent";
import AppointmentTableContent from "../components/app-components/Dashboard/AppointmentTableContent";

const LeaderDashboard = () => {
  // const dashboardItems = [
  //   { name: 'Add Clinic', icon: <HomeIcon /> },
  //   { name: 'Add Doctor', icon: <DoctorIcon className='h-10 w-10 group-hover:text-white'/> },
  //   { name: 'Add Schedule', icon: <HomeIcon /> },
  //   { name: 'View Schedule', icon: <HomeIcon /> },
  // ];

  return (
    <div>
      <div>
        <div className="mt-2 md:flex md:items-center md:justify-between">
          {/* Dashboard Header */}
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Dashboard
            </h2>
          </div>
          <div className="mt-4 flex shrink-0 md:ml-4 md:mt-0">
            {/* <button
              type="button"
              className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 overflow-hidden group"
            >
              <span class="relative z-10 group-hover:text-white">Back</span>
              <span class="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full h-full bg-gray-100 rounded-full scale-0 transition-transform duration-500 ease-out group-hover:scale-150 group-hover:bg-gray-800"></span>
            </button> */}
          </div>
        </div>

        {/* Dashboard Stats */}
        <StatsContent />

        {/* Dashboard Cards */}
        {/* <dl className="mt-5 grid grid-cols-1 gap-16 sm:grid-cols-4">
          {dashboardItems.map((item) => (
            <button className="group">
              <div
                key={item.name}
                className="overflow-hidden bg-white group-hover:bg-red-500 px-4 py-5 shadow sm:p-6 backdrop-blur-lg backdrop-saturate-[180%] rounded-xl border flex flex-col items-center transition-colors ease-in-out duration-300"
              >
                <dd className="text-3xl font-semibold tracking-tight text-gray-900 group-hover:text-white h-10 w-10">
                  {item.icon}
                </dd>
                <dt className="mt-2 text-sm font-medium text-gray-500 group-hover:text-white">
                  {item.name}
                </dt>
              </div>
            </button>
          ))}
        </dl> */}

        {/* <HospitalTableContent title="Hospitals / Clinics" /> */}
        {/* <AppointmentTableContent title="Active Appointments" /> */}
      </div>
    </div>
  );
};

export default LeaderDashboard;
