import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from "@nextui-org/react";
import { colgroup } from "framer-motion/client";

export default function DropDown({
  triggerRemount,
  name,
  option,
  options,
  selectedValue,
  onSelect,
  dropdownId,
  selection,
  setSelection,
  handleChange,
}) {
  const [selectedKeys, setSelectedKeys] = React.useState(
    new Set([selectedValue])
  );
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleDropdownToggle = (id) => {
    if (openDropdown === id) return;
    setOpenDropdown(id);
  };

  const handleSelectionChange = (keys) => {
    const selectedValue = Array.from(keys).join(", ").replaceAll("_", " ");
    // triggerRemount();
    setSelectedKeys(keys);

    handleChange(selectedValue);
  };

  const selectedText = Array.from(selectedKeys).join(", ").replaceAll("_", " ");

  return (
    <div className="flex flex-col items-center space-y-2 z-1">
      <label
        htmlFor={name.toLowerCase()}
        className="text-sm font-semibold text-gray-800 block text-center"
      >
        {name}
      </label>

      <Dropdown>
        <DropdownTrigger>
          <Button
            variant="bordered"
            className="shadow-sm capitalize rounded-md bg-rose-500 text-white text-sm hover:bg-rose-600 focus:ring-2 focus:ring-[#EA5454] transition-all duration-300 ease-in-out w-23 h-9 max-w-xs"
            onClick={() => handleDropdownToggle(dropdownId)}
          >
            {(selectedText.length > 15
              ? selectedText.slice(0, 11) + "..."
              : selectedText) ||
              `${option.length > 15 ? option.slice(0, 11) + "..." : option}`}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 ml-2 transform transition-transform duration-300 ease-in-out"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          aria-label={`Select ${name}`}
          variant="flat"
          disallowEmptySelection
          selectionMode="single"
          selectedKeys={selectedKeys}
          onSelectionChange={handleSelectionChange}
          className="bg-white w-60 rounded-lg shadow-xl"
          open={openDropdown === dropdownId}
        >
          {options.map((option) => (
            <DropdownItem
              key={option}
              className="hover:bg-[#fbd1cd] cursor-pointer rounded-lg p-2"
            >
              {option}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
