import { createBrowserRouter } from "react-router-dom";
import Rootlayout from "../pages/Rootlayout";

import Dashboard from "../pages/Dashboard";
import Doctors from "../pages/Doctors";
import Booking from "../pages/Booking";
import DoctorsList from "../pages/DoctorsList";
import AddDoctor from "../pages/AddDoctor";
import Appointment from "../pages/Appointment";
import AppointmentTableContent from "../components/app-components/Dashboard/AppointmentTableContent";
import Login from "../pages/Login";
import Leaderlayout from "../pages/Leaderlayout";
import LeaderDashboard from "../pages/LeaderDashboard";
import LeaderAppointmentTableContent from "../components/app-components/Dashboard/LeaderAppointmentTableContent";
import LeaderBooking from "../pages/LeaderBooking";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />, // Login page
  },
  {
    path: "/hospital",
    element: <Rootlayout />, // Root Layout with Navbar, etc.
    children: [
      { path: "", element: <Dashboard /> }, // Default route
      { path: "hmate/dashboard", element: <Dashboard /> },
      { path: "hmate/doctorslist", element: <DoctorsList /> },
      { path: "hmate/doctorslist/doctor/:doctorId", element: <Doctors /> },
      {
        path: "hmate/appointments/:hospitalId",
        element: <AppointmentTableContent />,
      },
      {
        path: "hmate/appointments",
        element: <AppointmentTableContent />,
      },
      { path: "hmate/bookings", element: <Booking /> },
      { path: "hmate/addDoctor", element: <AddDoctor /> },
    ],
  },
  {
    path: "/leader",
    element: <Leaderlayout />, // Root Layout with Navbar, etc.
    children: [
      { path: "", element: <LeaderDashboard /> }, // Default route
      { path: "hmate/dashboard", element: <LeaderDashboard /> },
      // { path: "hmate/doctorslist", element: <DoctorsList /> },
      // { path: "hmate/doctorslist/doctor/:doctorId", element: <Doctors /> },
      {
        path: "hmate/appointments/:hospitalId",
        element: <LeaderAppointmentTableContent />,
      },
      {
        path: "hmate/appointments",
        element: <LeaderAppointmentTableContent />,
      },
      { path: "hmate/bookings", element: <LeaderBooking /> },
      // { path: "hmate/addDoctor", element: <AddDoctor /> },
    ],
  },
]);

export default router;
