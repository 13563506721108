// import { useState } from "react";
import img from "../assets/icons/512.png";
import {
  Bars3Icon,
  BellIcon,
  HomeIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { CalendarDateRangeIcon } from "@heroicons/react/20/solid";
import { NavLink, Outlet } from "react-router-dom";
import { ReactComponent as DoctorIcon } from "../assets/icons/Doctor.svg";
// import {
//   Dialog,
//   DialogBackdrop,
//   DialogPanel,
//   TransitionChild,
// } from "@headlessui/react";

// const navigation = [
//   { name: "Dashboard", to: "/hmate/dashboard", icon: HomeIcon },
//   { name: "Doctors", to: "/hmate/doctorslist", icon: DoctorIcon },
//   {
//     name: "Appointments",
//     to: "/hmate/appointments",
//     icon: CalendarDateRangeIcon,
//   },
//   {
//     name: "Bookings",
//     to: "/hmate/bookings",
//     icon: CalendarDateRangeIcon,
//   },
// ];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

// export default function Example() {
//   const [sidebarOpen, setSidebarOpen] = useState(false);
//   const [isPanelOpen, setIsPanelOpen] = useState(false); // State for toggling the profile panel
//   const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false); // State for mobile sidebar visibility

//   // Toggle mobile sidebar visibility
//   const toggleMobileSidebar = () => {
//     setMobileSidebarOpen(!mobileSidebarOpen);
//   };

//   // Toggle profile panel visibility
//   const togglePanel = () => {
//     setIsPanelOpen(!isPanelOpen);
//   };

//   return (
//     <div className="flex h-screen overflow-hidden">
//       {/* Mobile Navbar */}
//       <div className="fixed top-0 left-0 right-0 z-10 flex items-center justify-end bg-gray-900 p-4 lg:hidden">
//         <button
//           onClick={toggleMobileSidebar}
//           className="text-white p-2 left-16"
//         >
//           {mobileSidebarOpen ? (
//             <XMarkIcon className="h-6 w-6" aria-hidden="true" />
//           ) : (
//             <Bars3Icon className="h-6 w-6" aria-hidden="true" />
//           )}
//         </button>
//       </div>

//       {/* Sidebar (visible on larger screens) */}
//       <div className="flex flex-col w-52 bg-gray-900 p-5 lg:block hidden">
//         <div className="flex h-16 shrink-0 mb-8 items-center space-x-3">
//           <img alt="Your Company" src="/512.png" className="h-8 w-auto" />
//           <span className="text-white text-xl tracking-wide font-semibold">
//             My State
//           </span>
//         </div>
//         <nav className="flex flex-1 flex-col">
//           <ul role="list" className="flex flex-1 flex-col gap-y-7">
//             <li>
//               <ul role="list" className="-mx-2 space-y-1">
//                 {navigation.map((item) => (
//                   <li key={item.name}>
//                     <NavLink
//                       to={item.to}
//                       className={({ isActive }) =>
//                         classNames(
//                           isActive
//                             ? "bg-gray-800 text-white"
//                             : "text-gray-400 hover:bg-gray-800 hover:text-white",
//                           "group flex gap-x-3 rounded-md p-2 text-sm font-semibold"
//                         )
//                       }
//                     >
//                       <item.icon
//                         aria-hidden="true"
//                         className="h-6 w-6 shrink-0"
//                       />
//                       {item.name}
//                     </NavLink>
//                   </li>
//                 ))}
//               </ul>
//             </li>
//           </ul>

//           {/* Profile Section in Desktop Sidebar (pushed to the bottom) */}
//           <div className="ml-3 relative min-h-screen bg-gray-900">
//             <div className="fixed bottom-10  bg-gray-800">
//               <a
//                 href="#"
//                 onClick={togglePanel}
//                 className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold text-white hover:bg-gray-800"
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke-width="1.5"
//                   stroke="currentColor"
//                   class="size-6"
//                 >
//                   <path
//                     stroke-linecap="round"
//                     stroke-linejoin="round"
//                     d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
//                   />
//                 </svg>
//                 <span>Hi User</span>
//               </a>
//             </div>
//           </div>
//         </nav>
//       </div>

//       {/* Mobile Sidebar with Transition */}
//       <Dialog
//         open={mobileSidebarOpen}
//         onClose={setMobileSidebarOpen}
//         className="relative z-50 lg:hidden"
//       >
//         <DialogBackdrop
//           transition
//           className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
//         />

//         <div className="fixed inset-0 flex">
//           <DialogPanel
//             transition
//             className="relative flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
//           >
//             <TransitionChild>
//               <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
//                 <button
//                   type="button"
//                   onClick={toggleMobileSidebar}
//                   className="-m-2.5 p-2.5"
//                 >
//                   <span className="sr-only">Close sidebar</span>
//                   <XMarkIcon
//                     aria-hidden="true"
//                     className="h-6 w-6 text-white"
//                   />
//                 </button>
//               </div>
//             </TransitionChild>

//             <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2">
//               <div className="flex h-16 shrink-0 items-center">
//                 <img
//                   alt="Your Company"
//                   src="https://cdn.prod.website-files.com/641a367d3597374ded5823b1/641ae4d07388d7f7dc82160c_Mivada_Icon_Melon_RGB.svg"
//                   className="h-8 w-auto"
//                 />
//               </div>

//               <nav className="flex flex-col gap-y-7">
//                 <ul role="list" className="flex flex-1 flex-col gap-y-7 ">
//                   <li>
//                     <ul role="list" className="-mx-2 space-y-1">
//                       {navigation.map((item) => (
//                         <li key={item.name}>
//                           <NavLink
//                             to={item.to}
//                             className={({ isActive }) =>
//                               classNames(
//                                 isActive
//                                   ? "bg-gray-800 text-white"
//                                   : "text-gray-400 hover:bg-gray-800 hover:text-white",
//                                 "group flex gap-x-3 rounded-md p-2 text-sm font-semibold"
//                               )
//                             }
//                           >
//                             <item.icon
//                               aria-hidden="true"
//                               className="h-6 w-6 shrink-0"
//                             />
//                             {item.name}
//                           </NavLink>
//                         </li>
//                       ))}
//                     </ul>
//                   </li>
//                 </ul>

//                 {/* Profile Section in Mobile Sidebar */}
//               </nav>
//             </div>
//             <div className="relative min-h-screen bg-gray-900">
//               <div className="fixed bottom-0 w-full bg-gray-800">
//                 <a
//                   href="#"
//                   onClick={togglePanel}
//                   className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold text-white hover:bg-gray-700"
//                 >
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="none"
//                     viewBox="0 0 24 24"
//                     stroke-width="1.5"
//                     stroke="currentColor"
//                     class="size-6"
//                   >
//                     <path
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                       d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
//                     />
//                   </svg>
//                 </a>
//               </div>
//             </div>
//           </DialogPanel>
//         </div>
//       </Dialog>

//       {/* Main content area */}
//       <div className="flex-1 overflow-y-auto p-6">
//         <Outlet />
//       </div>

//       {/* Profile Info Panel (Mobile and Desktop) */}
//     </div>
//   );
// }

import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from "@headlessui/react";
// import {
//   Bars3Icon,
//   BellIcon,
//   CalendarIcon,
//   ChartPieIcon,
//   Cog6ToothIcon,
//   DocumentDuplicateIcon,
//   FolderIcon,
//   HomeIcon,
//   UsersIcon,
//   XMarkIcon,
// } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

const navigation = [
  { name: "Dashboard", to: "/hospital/hmate/dashboard", icon: HomeIcon },
  { name: "Doctors", to: "/hospital/hmate/doctorslist", icon: DoctorIcon },
  {
    name: "Appointments",
    to: "/hospital/hmate/appointments",
    icon: CalendarDateRangeIcon,
  },
  {
    name: "Bookings",
    to: "/hospital/hmate/bookings",
    icon: CalendarDateRangeIcon,
  },
];

// const userNavigation = [
//   { name: 'Your profile', href: '#' },
//   { name: 'Sign out', href: '#' },
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div>
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-50 lg:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      aria-hidden="true"
                      className="size-6 text-white"
                    />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                {/* <div className="flex h-16 shrink-0 items-center"> */}
                <div className="flex h-16 shrink-0 mb-8 items-center space-x-3">
                  <img
                    alt="Your Company"
                    src="/512.png"
                    className="h-8 w-auto"
                  />
                  <span className="text-white text-xl tracking-wide font-semibold">
                    My State
                  </span>
                  {/* //         </div> */}
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <NavLink
                              to={item.to}
                              className={({ isActive }) =>
                                classNames(
                                  isActive
                                    ? "bg-gray-800 text-white"
                                    : "text-gray-400 hover:bg-gray-800 hover:text-white",
                                  "group flex gap-x-3 rounded-md p-2 text-sm font-semibold"
                                )
                              }
                            >
                              <item.icon
                                aria-hidden="true"
                                className="h-6 w-6 shrink-0"
                              />
                              {item.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>

                    <li className="mt-auto">
                      <a
                        href="#"
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-gray-400 hover:bg-gray-800 hover:text-white"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                        </svg>
                        <span>Hi User</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
            <div className="flex h-16 shrink-0 mb-8 items-center space-x-3">
              <img alt="Your Company" src="/512.png" className="h-8 w-auto" />
              <span className="text-white text-xl tracking-wide font-semibold">
                My State
              </span>
              {/* //         </div> */}
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.to}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? "bg-gray-800 text-white"
                                : "text-gray-400 hover:bg-gray-800 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold"
                            )
                          }
                        >
                          <item.icon
                            aria-hidden="true"
                            className="h-6 w-6 shrink-0"
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="mt-auto">
                  <a
                    href="#"
                    className="group -mx-2 flex items-center justify-center gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-gray-400 hover:bg-gray-800 hover:text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                    <span>Hi User</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          {/* <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"> */}
          {/* <button
              type="button"
              onClick={() => setSidebarOpen(true)}
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="size-6" />
            </button> */}

          {/* Separator */}
          <div
            aria-hidden="true"
            className="h-6 w-px bg-gray-900/10 lg:hidden"
          />

          {/* <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6"> */}
          {/* <form action="#" method="GET" className="grid flex-1 grid-cols-1">
                <input
                  name="search"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  className="col-start-1 row-start-1 block size-full bg-white pl-8 text-base text-gray-900 outline-none placeholder:text-gray-400 sm:text-sm/6"
                />
                <MagnifyingGlassIcon
                  aria-hidden="true"
                  className="pointer-events-none col-start-1 row-start-1 size-5 self-center text-gray-400"
                />
              </form> */}
          {/* <div className="flex items-center gap-x-4 lg:gap-x-6"> */}
          {/* <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="size-6" />
                </button> */}

          {/* Separator */}
          {/* <div
                  aria-hidden="true"
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                /> */}

          {/* Profile dropdown */}
          {/* <Menu as="div" className="relative">
                  <MenuButton className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img
                      alt=""
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      className="size-8 rounded-full bg-gray-50"
                    />
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        aria-hidden="true"
                        className="ml-4 text-sm/6 font-semibold text-gray-900"
                      >
                        Tom Cook
                      </span>
                      <ChevronDownIcon
                        aria-hidden="true"
                        className="ml-2 size-5 text-gray-400"
                      />
                    </span>
                  </MenuButton>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    {userNavigation.map((item) => (
                      <MenuItem key={item.name}>
                        <a
                          href={item.href}
                          className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                        >
                          {item.name}
                        </a>
                      </MenuItem>
                    ))}
                  </MenuItems>
                </Menu> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}

          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
