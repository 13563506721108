import { getRequest } from "./axios.js";

export const getAllAppointments = async () => {
  const url = `api/hospitalAppointment/getAllHospitalAppointments`;
  const response = await getRequest(url);
  return response;
};
export const getAppoinmentsByHospitalId = async (id) => {
  const url = `api/hospitalAppointment/getAppointmentsByHospital/${id}`;
  const response = await getRequest(url);
  return response;
};

// export default getAllAppointments;
