import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from "@heroicons/react/20/solid";
import { Transition } from "@headlessui/react";

import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { LinkIcon, QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { addDoctor } from "../api/doctorsAxios";

export default function AddDoctor({ open, setOpen }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const notificationMethods = [
    { id: "true", title: "True" },
    { id: "false", title: "False" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formDataObj = Object.fromEntries(formData.entries());
    console.log(formDataObj);
    const finalObj = {
      ...formDataObj,
      yearsOfExperience: parseInt(formDataObj.yearsOfExperience, 10),
      hospitalId: parseInt(formDataObj.hospitalId, 10),
      atcive: formDataObj.active.toLowerCase() === "true",
    };
    const postData = async () => {
      try {
        const response = addDoctor(finalObj);
        console.log(response);
      } catch (e) {
        console.log(e);
      }
    };
    postData();
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700 mt-0"
            >
              <form
                onSubmit={handleSubmit}
                className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
              >
                <div className="flex-1">
                  {/* Header */}
                  <div className="bg-gray-50 px-4 py-6 sm:px-6">
                    <div className="flex items-start justify-between space-x-3">
                      <div className="space-y-1">
                        <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                          Add Doctor
                        </DialogTitle>
                        <p className="text-sm text-gray-500">
                          Get started by filling in the information below to add
                          a doctor.
                        </p>
                      </div>
                      <div className="flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="relative text-gray-400 hover:text-gray-500"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Divider container */}
                  <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                    {/* Project name */}
                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="project-name"
                          className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >
                          First name
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          id="firstName"
                          name="firstName"
                          type="text"
                          className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    {/* Project description */}
                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="project-description"
                          className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >
                          Last Name
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          id="lastName"
                          name="lastName"
                          type="text"
                          className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="dob"
                          className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >
                          Date of Birth
                        </label>
                      </div>
                      <div className="sm:col-span-2 relative">
                        <input
                          id="dateOfBirth"
                          name="dateOfBirth"
                          type="text" // Change input type to 'text' for better flexibility
                          value={
                            selectedDate
                              ? selectedDate.toLocaleDateString("en-CA") // Use 'en-CA' for ISO format (YYYY-MM-DD)
                              : ""
                          }
                          onChange={(e) => {
                            const date = new Date(e.target.value); // Parse date from input
                            if (!isNaN(date)) {
                              setSelectedDate(date); // Update state if valid date
                            }
                          }}
                          placeholder="YYYY-MM-DD"
                          className="w-full rounded-md border-0 py-2 pr-10 px-3 text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600"
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-3 flex items-center text-gray-500 hover:text-gray-700"
                          onClick={() => setShowCalendar(!showCalendar)}
                        >
                          <CalendarIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                          />
                        </button>
                        {showCalendar && (
                          <div className="absolute top-12 right-0 z-10 bg-white shadow-lg rounded-lg border border-gray-300">
                            <DatePicker
                              selected={selectedDate}
                              onChange={(date) => {
                                setSelectedDate(date);
                                setShowCalendar(false);
                              }}
                              inline
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="gender"
                          className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >
                          Gender
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <select
                          id="gender"
                          name="gender"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          <option value="" disabled selected>
                            Select Gender
                          </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>
                    </div>

                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="specialty"
                          className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >
                          Speciality
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          id="specialty"
                          name="specialty"
                          type="text"
                          className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="qualification"
                          className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >
                          Qualification
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          id="qualification"
                          name="qualification"
                          type="text"
                          className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="yearsOfExperience"
                          className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >
                          Years of Experience
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          id="yearsOfExperience"
                          name="yearsOfExperience"
                          type="text"
                          className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="contactNumber"
                          className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >
                          Contact Number
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          id="contactNumber"
                          name="contactNumber"
                          type="text"
                          className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >
                          Email Address
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="address"
                          className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >
                          Address
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          id="address"
                          name="address"
                          className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="hospitalId"
                          className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >
                          Hospital
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          id="hospitalId"
                          name="hospitalId"
                          className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="scheduleDetails"
                          className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >
                          Schedule Details
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          id="scheduleDetails"
                          name="scheduleDetails"
                          className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="active"
                          className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >
                          Active
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          type="radio"
                          id="true"
                          name="active"
                          value="True"
                        />
                        <label for="true" className="ml-2">
                          True
                        </label>
                        <input
                          type="radio"
                          id="false"
                          name="active"
                          value="False"
                          className="ml-5"
                        />
                        <label for="false" className="ml-2">
                          False
                        </label>

                        {/* <div className=" space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                            {notificationMethods.map((notificationMethod) => (
                              <div
                                key={notificationMethod.id}
                                className="flex items-center"
                              >
                                <input
                                  defaultChecked={
                                    notificationMethod.id === "email"
                                  }
                                  id={notificationMethod.id}
                                  name="notification-method"
                                  type="radio"
                                  className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                                />
                                <label
                                  htmlFor={notificationMethod.id}
                                  className="ml-3 block text-sm/6 font-medium text-gray-900"
                                >
                                  {notificationMethod.title}
                                </label>
                              </div>
                            ))}
                          </div> */}
                      </div>
                    </div>
                    {/* Privacy */}
                  </div>
                </div>

                {/* Action buttons */}
                <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className="flex justify-end space-x-3">
                    <button
                      type="button"
                      onClick={() => setOpen(false)}
                      className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </form>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
