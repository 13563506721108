import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import DropDown from "./DropDown";
import { getAllDoctors } from "../../../api/doctorsAxios";
import getAllHospitals from "../../../api/hospitalAxios";
import getViewDoctors from "../../../api/viewDoctor";

const Helper = ({ timeSlots, setTimeSlots, selection, setSelection }) => {
  const [startTime, setStartTime] = useState({
    hour: 9,
    minute: 0,
    ampm: "AM",
  });
const [hospitals, setHospitals] = useState([]);
  // const doctors = ["harsha", "moin", "mubeen", "jayanth"];
  const [doctors, setDoctors] = useState([]);
   // Fetch hospitals on component mount
   useEffect(() => {
    
    const fetchHospitals = async () => {
      try {
        const response = await getAllHospitals();
        setHospitals(
          response.data.body.map((hospital) => ({
            id: hospital.hospitalID,
            name: hospital.hospitalName,
          }))
        );
      } catch (error) {
        console.error("Error fetching hospitals:", error);
      }
    };
    fetchHospitals();
  }, []);

  // Fetch doctors when a hospital is selected
  useEffect(() => {
    if (selection.hospital) {
      const selectedHospital = hospitals.find(
        (h) => h.name === selection.hospital
      );

      if (selectedHospital) {
        
        const fetchDoctors = async () => {
          try {
            const response = await getViewDoctors(selectedHospital.id); // Pass hospital ID to API
            setDoctors(
              response.data.body.map(
                (doctor) => `${doctor.firstName} ${doctor.lastName}`
              )
            );
          } catch (error) {
            console.error("Error fetching doctors:", error);
          }
        };
        fetchDoctors();
      }
    }
  }, [selection.hospital, hospitals]);

  const handleHospitalChange = (hospitalName) => {
    setSelection((prev) => ({
      ...prev,
      hospital: hospitalName,
      doctor: "", // Reset doctor when hospital changes
    }));
    setDoctors([]); // Clear doctors list while loading new doctors
  };

  const handleDoctorChange = (doctorName) => {
    setSelection((prev) => ({
      ...prev,
      doctor: doctorName,
    }));
  };

  const adjustTime = (timeType, action) => {
    const adjustment = action === "increment" ? 1 : -1;

    if (focusedField === `${timeType}-hour`) {
      const newHour =
        timeType === "start"
          ? startTime.hour + adjustment
          : endTime.hour + adjustment;
      if (timeType === "start") {
        adjustTimeInput("start", "hour", newHour);
      } else {
        adjustTimeInput("end", "hour", newHour);
      }
    } else if (focusedField === `${timeType}-minute`) {
      const newMinute =
        timeType === "start"
          ? startTime.minute + adjustment * 5
          : endTime.minute + adjustment * 5; // Increment or decrement by 5 minutes
      if (timeType === "start") {
        adjustTimeInput("start", "minute", newMinute);
      } else {
        adjustTimeInput("end", "minute", newMinute);
      }
    }
  };

  const [endTime, setEndTime] = useState({ hour: 5, minute: 0, ampm: "PM" });
  const [interval, setInterval] = useState(30); // default interval is 30 minutes
  const [focusedField, setFocusedField] = useState("");

  const adjustTimeInput = (timeType, unit, value) => {
    if (unit === "minute") {
      value = Math.min(59, Math.max(0, value)); // Clamp minute between 0 and 59
    }

    if (unit === "hour") {
      value = Math.min(12, Math.max(1, value)); // Clamp hour between 1 and 12
    }

    if (timeType === "start") {
      setStartTime((prevState) => {
        const newTime = { ...prevState };
        if (unit === "hour") {
          newTime.hour = value;
        } else if (unit === "minute") {
          newTime.minute = value;
        }
        return newTime;
      });
    } else if (timeType === "end") {
      setEndTime((prevState) => {
        const newTime = { ...prevState };
        if (unit === "hour") {
          newTime.hour = value;
        } else if (unit === "minute") {
          newTime.minute = value;
        }
        return newTime;
      });
    }
  };

  const convertTo24Hour = (hour, ampm) => {
    if (ampm === "AM" && hour === 12) {
      return 0; // Midnight
    }
    if (ampm === "PM" && hour !== 12) {
      return hour + 12; // Convert PM hours to 24-hour format
    }
    return hour;
  };

  const generateTimeSlots = () => {
    const slots = [];

    let currentTime = new Date();
    const startHour24 = convertTo24Hour(startTime.hour, startTime.ampm);
    const endHour24 = convertTo24Hour(endTime.hour, endTime.ampm);

    currentTime.setHours(startHour24, startTime.minute, 0, 0);

    const end = new Date();
    end.setHours(endHour24, endTime.minute, 0, 0);

    const intervalMs = interval * 60 * 1000; // Convert interval to milliseconds

    while (currentTime < end) {
      const startFormatted = formatTime12Hour({
        hour: currentTime.getHours(),
        minute: currentTime.getMinutes(),
        ampm: currentTime.getHours() >= 12 ? "PM" : "AM",
      });

      // Calculate the end time for this slot
      const endTime = new Date(currentTime.getTime() + intervalMs);
      const endFormatted = formatTime12Hour({
        hour: endTime.getHours(),
        minute: endTime.getMinutes(),
        ampm: endTime.getHours() >= 12 ? "PM" : "AM",
      });

      // Push the time range (start time - end time) to the slots array
      slots.push(`${startFormatted} - ${endFormatted}`);

      // Move current time to the next slot
      currentTime = new Date(currentTime.getTime() + intervalMs);
    }

    setTimeSlots(slots);
  };

  const formatTime12Hour = ({ hour, minute, ampm }) => {
    return `${String(hour % 12 || 12).padStart(2, "0")}:${String(
      minute
    ).padStart(2, "0")} ${ampm}`;
  };

  const removeTimeSlot = (time) => {
    setTimeSlots((prevSlots) => prevSlots.filter((slot) => slot !== time));
  };

  return (
    <div className="bg-white p-4 pb-3 rounded-lg shadow-xl w-full">
               <div className="flex justify-center space-x-10 mb-5">
        <DropDown
                option={selection.hospital || "Hospitals"}
                name="Hospitals"
                options={hospitals.map((hospital) => hospital.name)}
                selection={selection}
                setSelection={setSelection}
                dropdownId={2}
                handleChange={handleHospitalChange}
                // triggerRemount={triggerRemount}
              />
              <DropDown
                option={selection.doctor || "Doctor"}
                name="Doctor"
                options={doctors}
                selection={selection}
                setSelection={setSelection}
                dropdownId={1}
                handleChange={handleDoctorChange}
                // triggerRemount={triggerRemount}
              />
        </div>
      <div className="space-y-6 mr-6">
        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-6">
          {/* Start Time */}
          <div className="flex flex-col items-center flex-1">
            <label
              htmlFor="start_time"
              className=" rounded-md px-1 text-md font-medium text-gray-900 mb-1"
            >
              From
            </label>
            <div className="relative flex items-center w-36 h-12 bg-white rounded-md shadow-md">
              {/* Hour Input */}
              <input
                type="text"
                value={String(startTime.hour).padStart(2, "0")}
                min="1"
                max="12"
                onChange={(e) =>
                  adjustTimeInput("start", "hour", Number(e.target.value))
                }
                onFocus={() => setFocusedField("start-hour")}
                className={`w-14 text-center text-gray-900 ${
                  focusedField === "start-hour"
                    ? "bg-[#ffded3] focus:ring-0"
                    : "bg-white focus:ring-0"
                } border-none outline-none rounded-md`}
              />
              <span className="text-gray-500">:</span>
              {/* Minute Input */}
              <input
                type="text"
                value={String(startTime.minute).padStart(2, "0")}
                min="0"
                max="59"
                onChange={(e) =>
                  adjustTimeInput("start", "minute", Number(e.target.value))
                }
                onFocus={() => setFocusedField("start-minute")}
                className={`w-14 text-center text-gray-900 ${
                  focusedField === "start-minute"
                    ? "bg-[#ffded3] focus:ring-0"
                    : "bg-white focus:ring-0"
                } border-none outline-none rounded-md`}
              />
              <select
                value={startTime.ampm}
                onChange={(e) =>
                  setStartTime({ ...startTime, ampm: e.target.value })
                }
                className="w-16 bg-white text-gray-500 font-medium focus:outline-none ml-8"
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>

              {/* Increment/Decrement Buttons for Start Hour */}
              <button
                type="button"
                onClick={() => adjustTime("start", "increment")}
                className="absolute top-1 right-1 p-1 text-gray-500 hover:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
              </button>
              <button
                type="button"
                onClick={() => adjustTime("start", "decrement")}
                className="absolute bottom-1 right-1 p-1 text-gray-500 hover:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* End Time */}
          <div className="flex flex-col items-center flex-1 mt-6 sm:mt-0">
            <label
              htmlFor="end_time"
              className="rounded-md px-1 text-md font-medium text-gray-900 mb-1"
            >
              To
            </label>
            <div className="relative flex items-center w-36 h-12 bg-white rounded-md shadow-md">
              {/* Hour Input */}
              <input
                type="text"
                value={String(endTime.hour).padStart(2, "0")}
                min="1"
                max="12"
                onChange={(e) =>
                  adjustTimeInput("end", "hour", Number(e.target.value))
                }
                onFocus={() => setFocusedField("end-hour")}
                className={`w-14 text-center text-gray-900 ${
                  focusedField === "end-hour"
                    ? "bg-[#ffded3] focus:ring-0"
                    : "bg-white focus:ring-0"
                } border-none outline-none rounded-md`}
              />
              <span className="text-gray-500">:</span>
              {/* Minute Input */}
              <input
                type="text"
                value={String(endTime.minute).padStart(2, "0")}
                min="0"
                max="59"
                onChange={(e) =>
                  adjustTimeInput("end", "minute", Number(e.target.value))
                }
                onFocus={() => setFocusedField("end-minute")}
                className={`w-14 text-center text-gray-900 ${
                  focusedField === "end-minute"
                    ? "bg-[#ffded3] focus:ring-0"
                    : "bg-white focus:ring-0"
                } border-none outline-none rounded-md`}
              />
              <select
                value={endTime.ampm}
                onChange={(e) =>
                  setEndTime({ ...endTime, ampm: e.target.value })
                }
                className="w-16 bg-white text-gray-500 font-medium focus:outline-none ml-8"
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>

              {/* Increment/Decrement Buttons for End Hour */}
              <button
                type="button"
                onClick={() => adjustTime("end", "increment")}
                className="absolute top-1 right-1 p-1 text-gray-500 hover:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
              </button>
              <button
                type="button"
                onClick={() => adjustTime("end", "decrement")}
                className="absolute bottom-1 right-1 p-1 text-gray-500 hover:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Interval Input */}
        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-6">
          <div className="flex flex-col items-center flex-1">
            <label
              htmlFor="interval"
              className="rounded-md px-1 text-md font-medium text-gray-900 mb-1"
            >
              Interval (minutes)
            </label>
            <input
              type="number"
              value={interval}
              min="1"
              onChange={(e) => setInterval(Number(e.target.value))}
              className="w-20 h-12 text-center text-gray-900 border-none outline-none rounded-md"
            />
          </div>
        </div>

        {/*selecting doctor and hospital*/}


        {/* Time Slot Generation Button */}
        <div className="flex justify-center mt-4">
          <button
            onClick={generateTimeSlots}
            className="mt-3 mx-auto w-full rounded-md bg-red-500 px-3 py-2 text-md font-semibold text-white shadow hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition duration-200 ease-in-out transform hover:scale-105 active:scale-100"
          >
            Generate Time Slots
          </button>
        </div>

        {/* Display Time Slots */}
        <div className="mt-8">
          {timeSlots.length > 0 && (
            <>
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Generated Time Slots:
              </h3>
              <ul className="space-y-2">
                {timeSlots.map((slot, index) => (
                  <li
                    key={index}
                    className="inline-flex items-center gap-x-0.5 mx-4 rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                  >
                    {slot}
                    <button
                      type="button"
                      onClick={() => removeTimeSlot(slot)}
                      className="group relative -mr-1 size-3.5 rounded-sm hover:bg-green-600/20"
                    >
                      <span className="sr-only">Remove</span>
                      <svg
                        viewBox="0 0 14 14"
                        className="h-3.5 w-3.5 fill-current text-gray-400 group-hover:text-gray-500"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path d="M6.293 7l3.207-3.207a1 1 0 0 1 1.414 1.414L7.707 8l3.207 3.207a1 1 0 0 1-1.414 1.414L6.293 9l-3.207 3.207a1 1 0 0 1-1.414-1.414L4.587 8 1.38 4.793a1 1 0 1 1 1.414-1.414L6.293 7z" />
                      </svg>
                    </button>
                  </li>
                ))}
              </ul>
            </>
          )}
          {timeSlots.length === 0 && (
            <p className="text-center text-gray-500">
              No time slots generated.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Helper;
